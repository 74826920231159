var toggle = function() {
  this.el = null,
  this.toggle = null,
  this.list = null,
  this.state = null;

  this.init = function($id, $options = null) {
    this.el = document.querySelector($id),
    this.toggle = this.el.querySelectorAll('[data-toggle]'),
    this.list = this.el.querySelector('[data-panel]'),
    this.options = $options;

    for(var $i = 0; $i < this.toggle.length; $i++) {
      this.addListener({
        toggle: this.toggle[$i],
        list: this.list
      });
    }

    return this;
  }

  this.addListener = function($options) {
    $options.toggle.onclick = function() {
      this.toggleClass({
        toggle: $options.toggle,
        list: $options.list
      });
      return false;
    }.bind(this);
  }

  this.toggleClass = function($options) {
    if(this.state) {
      this.state = null;

      $options.list.classList.remove('is-active');

      if(this.options.overflow) {
        document.querySelector('body').style.overflow = null;
      }
    } else {
      this.state = 'is-active';

      $options.list.classList.add('is-active');

      if(this.options.overflow) {
        document.querySelector('body').style.overflow = this.options.overflow;
      }
    }
  }
}

var navMobile = new toggle().init('[data-mobile]', {
  overflow: 'hidden'
});