var googleMap = function($data) {
  this.data = $data,
  this.markers = new Array(),
  this.infowindows = new Array(),
  this.bounds = new google.maps.LatLngBounds();

  // Init map
  this.map = new google.maps.Map(document.getElementById('map'), {
    center: {
      lat: 50.4956976, 
      lng: 3.3452619
    },
    zoom: 8,
    disableDefaultUI: true
  });
  
  // Add locations
  for(var $i = 0; $i < this.data.length; $i++) {
    // Add to bounds
    this.bounds.extend(new google.maps.LatLng(this.data[$i].lat, this.data[$i].lng));
  
    // Add marker
    var $marker = new google.maps.Marker({
      position: new google.maps.LatLng(this.data[$i].lat, this.data[$i].lng), 
      title: this.data[$i].location,
      map: this.map
    });

    this.markers[$i] = $marker;

    // Add infowindow
    var $infowindow = new google.maps.InfoWindow({
      content: `
      <div class="infowindow text-container">
        <p class="infowindow-title">` + this.data[$i].location + `</p>
        <p>
          ` + this.data[$i].address + `<br>
          ` + this.data[$i].zip + ` ` + this.data[$i].city + `
        </p>
      </div>
      `
    });

    this.infowindows[$i] = $infowindow;

    // Add event listener
    addListener($marker, $infowindow);
  };

  // Add event listener
  function addListener($marker, $infowindow) {
    $marker.addListener('click', function() {
      $infowindow.open(this.map, $marker);
    });
  }

  // Fit bounds
  function fitBounds($map, $bounds) {
    if(window.innerWidth >= 960) {
      $map.fitBounds($bounds, {
        top: 100,
        bottom: 192,
        left: 100,
        right: 100
      });
    } else {
      $map.fitBounds($bounds, {
        top: 48,
        bottom: 48,
        left: 48,
        right: 48
      });
    }
  }

  fitBounds(this.map, this.bounds);

  google.maps.event.addListenerOnce(this.map, 'bounds_changed', function() { 
    if(this.map.getZoom() > 15) {
      this.map.setZoom(15);
    }
  }.bind(this));
}