var floatingLabels = function() {
  this.inputFields = [];

  this.init = function($el) {
    var $nodeList = document.querySelectorAll($el);

    for(var $i = 0; $i < $nodeList.length; $i++) {
      var $field = null;

      // If input
      if($nodeList[$i].querySelector('input')) {
        $field = $nodeList[$i].querySelector('input');
      }

      // If textarea
      if($nodeList[$i].querySelector('textarea')) {
        $field = $nodeList[$i].querySelector('textarea');
      }

      // If select
      if($nodeList[$i].querySelector('select')) {
        $field = $nodeList[$i].querySelector('select');
      }

      // Create inputfields array
      this.inputFields[$i] =  {
        group: $nodeList[$i],
        label: $nodeList[$i].querySelector('label'),
        field: $field
      }

      // Set state on init
      this.toggleClass(this.inputFields[$i]);

      // Add event listener
      this.addListener(this.inputFields[$i]);
    }

    return this;
  }

  this.addListener = function($inputField) {
    $inputField.field.oninput = function() {
      // Update state on input
      this.toggleClass($inputField);
    }.bind(this);
  }

  this.toggleClass = function($inputField) {
    // Check and update current state
    if($inputField.field.value != '') {
      $inputField.group.classList.add('is-floating');
    } else {
      $inputField.group.classList.remove('is-floating');
    }
  }
};