var masonryGallery = function() {
  this.el = null;

  this.init = function($id) {
    this.el = document.querySelector($id);
    this.baguettebox = baguetteBox.run($id, {
      filter: /.*/i,
      captions: false
    });
    this.masonry = new Masonry(this.el);

    return this;
  }
}
